<template>
  <div>
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="12" sm="12" cols="12">
                <v-row>
                  <v-col md="3" sm="12" cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      vid="configuracaoNovo.area_id"
                    >
                      <v-autocomplete
                        append-icon=""
                        v-model="configuracaoNovo.area_id"
                        dense
                        outlined
                        label="Áreas"
                        :items="areas"
                        :readonly="true"
                        item-text="nome"
                        item-value="id"
                        :loading="$store.state.layout.loading"
                        :disabled="$store.state.layout.loading"
                        :error-messages="errors"
                        autocomplete="false"
                        hide-details="auto"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col md="2" sm="12" cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      vid="configuracaoNovo.adquirente"
                    >
                      <autocomplete-adquirente
                        v-model="configuracaoNovo.adquirente"
                        :readonly="true"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col
                    md="2"
                    sm="12"
                    cols="12"
                    v-show="configuracaoNovo.adquirente == 1"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      vid="configuracaoNovo.cod_estabelecimento"
                    >
                      <v-text-field
                        v-model="configuracaoNovo.cod_estabelecimento"
                        outlined
                        dense
                        :readonly="true"
                        hide-details="auto"
                        :disabled="$store.state.layout.loading"
                        label="Cod. Estabelecimento"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col md="3" sm="12" cols="12" class="mt-1">
                    <v-switch
                      v-model="configuracaoNovo.cobrar_entrada"
                      class="mt-0 pt-0"
                      :readonly="
                        $store.getters['usuario/temPermissao'](
                          'mostrar.area_configuracao'
                        ) &&
                        !$store.getters['usuario/temPermissao'](
                          'editar.area_configuracao'
                        )
                      "
                      :disabled="$store.state.layout.loading"
                      label="Cobrar na entrada"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-row md="12" sm="12" cols="12" class="ma-1">
                <div class="col-5 text-center ticket">
                  <v-card class="elevation-0">
                    <v-tabs fixed-tabs class="align-with-title">
                      <v-tab @click="ticket_condicional = true">
                        Ticket Saida
                      </v-tab>

                      <v-tab @click="ticket_condicional = false">
                        Ticket Entrada
                      </v-tab>
                    </v-tabs>

                    <v-textarea
                      v-if="ticket_condicional"
                      class="px-1"
                      flat
                      cols="10"
                      v-model="configuracaoNovo.layout_ticket"
                      no-resize
                      ref="myTextarea"
                      auto-grow
                      :disabled="$store.state.layout.loading"
                      @input="limitarPorLinha"
                    />

                    <v-textarea
                      v-else
                      class="px-1"
                      flat
                      cols="10"
                      v-model="configuracaoNovo.layout_ticket_entrada"
                      no-resize
                      ref="myTextarea"
                      auto-grow
                      :disabled="$store.state.layout.loading"
                      @input="limitarPorLinha"
                    />
                  </v-card>

                  <v-row class="d-flex justify-space-around ma-1">
                    <btn-salvar
                      class="mt-1"
                      :temPermissao="
                        $store.getters['usuario/temPermissao'](
                          'editar.area_configuracao'
                        )
                      "
                      :carregando="carregandoSalvar"
                      @click="$emit('salvar', configuracaoNovo)"
                    />

                    <btn-visualizar class="mt-1" />
                  </v-row>
                </div>

                <v-col style="order: 1" />

                <div class="col-6 text-center opcoes grey lighten-3">
                  <h3>Opções</h3>
                  <v-btn
                    v-for="element in tags"
                    :key="element.id"
                    class="my-1 tagsBotao"
                    @click="inserirPalavra(element.tag)"
                  >
                    {{ element.caption }}
                  </v-btn>
                </div>
              </v-row>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
    </v-card>
  </div>
</template>

<script>
import areaConfiguracao from "@/api/areaconfiguracao";

export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    configuracao: {
      type: Object,
      default: () => {
        return {};
      },
    },
    areas: {
      type: Array,
      default: () => {},
    },
  },

  data() {
    return {
      configuracaoNovo: {
        area_id: null,
        adquirente: null,
        cod_estabelecimento: null,
        cobrar_entrada: null,
        layout_ticket: null,
        start: 0,
      },
      tags: [],
      ticket_condicional: true,
      tab: null,
      items: ["Ticket Entrada", "Ticket Saida"],
    };
  },

  mounted() {
    this.carregarTags();
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;
      return this.$refs.observer.setErrors(val);
    },

    configuracao(configuracao) {
      this.configuracaoNovo = configuracao;
    },

    areas(areas) {
      this.areas = areas;
    },

    "configuracaoNovo.layout_ticket"(valor) {
      if (this.ticket_condicional) {
        this.$store.commit(`modal/SET_TICKETEXTO`, valor);
        // this.$store.state.modal.ticketTexto = valor;
      }
    },
    "configuracaoNovo.layout_ticket_entrada"(valor) {
      if (!this.ticket_condicional) {
        // this.$store.state.modal.ticketTexto = valor;
        this.$store.commit(`modal/SET_TICKETEXTO`, valor);
      }
    },
    ticket_condicional() {
      let dado = this.ticket_condicional
        ? this.configuracaoNovo.layout_ticket
        : this.configuracaoNovo.layout_ticket_entrada;

      this.$store.commit(`modal/SET_TICKETEXTO`, dado);
    },
  },

  methods: {
    async carregarTags() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await areaConfiguracao.tags();
        this.tags = resposta.data.data;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        console.log(e);
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    inserirPalavra(valor) {
      const textarea = this.$refs.myTextarea.$refs.input;

      let textoAtual = this.ticket_condicional
        ? this.configuracaoNovo.layout_ticket
        : this.configuracaoNovo.layout_ticket_entrada;

      const posicaoCursor = textarea.selectionStart;

      const textoAntesCursor = textoAtual.substring(0, posicaoCursor);

      const textoDepoisCursor = textoAtual.substring(posicaoCursor);

      textoAtual = textoAntesCursor + valor + textoDepoisCursor;

      this.configuracaoNovo[
        this.ticket_condicional ? "layout_ticket" : "layout_ticket_entrada"
      ] = textoAntesCursor + valor + textoDepoisCursor;
    },

    limitarPorLinha() {
      const textarea = this.$refs.myTextarea.$refs.input;
      let linhas = textarea.value.split("\n");
      const limiteDeCaracteres = 30;
      const linhasLimitadas = linhas.map((linha) =>
        linha.slice(0, limiteDeCaracteres)
      );
      textarea.value = linhasLimitadas.join("\n");
      this.configuracaoNovo[
        this.ticket_condicional ? "layout_ticket" : "layout_ticket_entrada"
      ] = textarea.value;
    },
  },
};
</script>

<style scoped>
.ticket {
  border: 1px solid grey-;
  border-radius: 8px;
  padding: 5px;
  order: 2;
}
.opcoes {
  border-radius: 8px;
  padding: 5px;
  display: flex;
  /* flex-direction: column;*/
  justify-content: space-evenly;
  flex-wrap: wrap;
  order: 0;
  max-height: 500px;
  min-height: 500px;
}
.opcoes h3 {
  width: 100%;
  color: #212121 !important;
}

.tagsBotao {
  width: 48%;
}

.v-text-field__details {
  display: none !important;
}

@media screen and (max-width: 960px) {
  .tagsBotao {
    width: 100%;
  }
  .opcoes {
    max-height: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .col-6 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .col-5 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    width: 100% !important;
  }
}
</style>
